@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');
$heading-font: 'Orbitron',
sans-serif;

$body-font: 'Roboto',
sans-serif;

* {
  box-sizing: border-box;
}

$header-height: 64px; // with header
$header-height: 0px;
$footer-height: 32px;

.header {
  height: $header-height;
  background: #333;
  display: flex;
  flex-direction: row;


  .header__title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    .header__logo {
      svg {
        height: 32px;
        width: 32px;
        margin: 0 12px 0 16px;
      }
    }

    .header__name {
      font-family: $heading-font;
      color: #eee;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 2px;

    }
  }

  nav {
    height: 100%;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      padding: 0;
      margin: 0;
      height: 100%;
      font-size: 14px;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: fit-content;
        cursor: pointer;

        a {
          padding: 0 16px;
          text-decoration: none;
          color: #eee;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all .5s ease-in-out;

          &:hover {
            background: rgba(0, 0, 0, .50);
          }
        }
      }
    }
  }
}


.main-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
  height: calc(100vh - ($header-height + $footer-height));
  width: 100vw;

  .page__home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100vw;

    background: #222;

    .title {
      font-family: $heading-font;
      color: #eee;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 2px;
      margin-top: 8px;
    }
  }

  .page__email-signature {

    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;
    height: 100%;
    width: 100vw;

    .left-drawer {
      background-color: rgba(0, 0, 0, .025);
      border-right: 1px solid rgba(0, 0, 0, .10);
      box-shadow: 0 0 8px rgba(0, 0, 0, .10);
      height: 100%;
      width: 320px;
      padding: 16px 0;
      font-size: 14px;

      .instructions {
        margin-bottom: 16px;
        font-size: 12px;
        padding: 0 32px;
      }

      .instructions-hr {
        margin: 16px;
        border: none;
        border-top: 1px solid rgba(0, 0, 0, .10);
      }

      .form-fields-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 64px 0 32px;

        label {
          font-size: 10px;
          text-align: left;
          color: #777;
          width: 100%;
          padding: 0;
          margin: 0;
        }

        input {
          width: 100%;
          margin: 1px 0 8px;
          padding: 4px 8px;
          border: 1px solid rgba(0, 0, 0, .5);
          border-radius: 4px;
          background-color: #fff;
        }

        .attach-image-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          position: relative;

          .image-input {
            //width: calc(100% - 64px);
          }

          .btn-clear-image {
            font-size: 24px;
            cursor: pointer;
            width: fit-content;
            padding: 0;
            margin: 0;
            transition: all .5s ease-in-out;
            position: absolute;
            top: .17em;
            right: 4px;

            &:hover {
              color: red;
            }
          }  
        }

        .style-select {
          display: none;
          width: 100%;
          margin: 1px 0 8px;
          padding: 4px 8px;
          border: 1px solid rgba(0, 0, 0, .5);
          border-radius: 4px;
          background-color: #fff;
        }
      }

      .btn-copy-container {
        display: flex;
        align-items: center;
        // justify-content: flex-end;
        margin: 16px 32px;

        .btn-copy {
          cursor: pointer;
          background: #333;
          color: white;
          border-radius: 4px;
          width: fit-content;
          padding: 8px 24px;
          transition: all .5s ease-in-out;

          &:hover {
            background: #777;
            color: white;
          }
        }
      }
    }

    .preview-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: -96px;

      .signature-preview-title{
        font-size: 24px;
        color: #555;
        font-weight: 700;
        margin: 0 0 8px;
        padding: 0;
        text-align: left;
        width: 640px;
      }

      .mock-email-client {
        display: flex;
        flex-direction: column;
        width: 640px;
        // height: 512px;
        font-size: 12px;
        color: #777;
        border: 1px solid rgba(0, 0, 0, .10);
        border-radius: 4px;
        box-shadow: 0 0px 4px rgba(0, 0, 0, .10);

        .mec__header {
          background-color: #eee;
          padding: 4px 8px;
          color: #222;
          font-weight: 500;
        }

        .mec__to,
        .mec__subject {
          padding: 8px 0;
          margin: 0 8px;
          border-bottom: 1px solid #eee;
        }

        .mec__body {
          font-size: 14px;
          padding: 32px;
          flex: 1;
          display: flex;
          flex-direction: column;

          .mec__body_content {
            flex: 1;
            color: #333;
          }
        }

        .mec__footer {
          padding: 8px;
          align-self: flex-end;

          .btn-send {
            background: #0b57d0;
            color: white;
            border-radius: 4px;
            width: fit-content;
            padding: 8px 24px;
          }
        }
      }
    }
  }
}

.footer {
  font-family: $heading-font;
  height: $footer-height;
  background: #333;
  color: #eee;
  display: flex;

  align-items: center;

  .footer-copy {
    font-size: 12px;
    width: 100%;
    text-align: right;
    margin: 0 32px;
  }
}




/*
************************************
** Color Palette Generator
** from: https://themera.vercel.app/
************************************
*/

// $branding: (
//     $colors: (
//         $primary: (
//             $c--50: "#F0F2F4",
//             $c--100: "#D6DBE0",
//             $c--200: "#BCC4CD",
//             $c--300: "#A2ACB9",
//             $c--400: "#8895A5",
//             $c--500: "#6E7E91",
//             $c--600: "#586574",
//             $c--700: "#424C57",
//             $c--800: "#2C323A",
//             $c--900: "#16191D"
//         ),
//         $secondary: (
//             $c--50: "#FBF5EA",
//             $c--100: "#F3E3C3",
//             $c--200: "#ECD19D",
//             $c--300: "#E4BF77",
//             $c--400: "#DDAE50",
//             $c--500: "#D59C2A",
//             $c--600: "#AA7D22",
//             $c--700: "#805D19",
//             $c--800: "#553E11",
//             $c--900: "#2B1F08"
//         ),
//     ),
//     $fonts: (
//         $heading: 'Orbitron', sans-serif,
//         $body: 'Roboto', sans-serif,
//     ),
    
// )